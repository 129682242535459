<template>
  <v-row class="d-flex flex-column">
    <v-col>
      <v-row>
        <v-breadcrumbs :items="breadcrumbs" class="px-4">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>

      <v-form
        ref="form"
        v-model="form.valid"
        lazy-validation>
        <v-row class="pt-1">
          <v-col cols="12">
            <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-0 mt-3 mb-3"
            >
              {{ ui.alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="4" class="pt-0 d-xs-none">
            <v-card outlined
                    class="rounded-lg overflow-hidden">
              <v-row
                style="border-radius: 0"
                class="blue py-6 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="d-flex flex-column title white--text pl-0">
                    <v-icon class="my-10" size="125">mdi-domain</v-icon>
                    {{
                      (_.isEmpty(form.default_data.name) ? $vuetify.lang.t('$vuetify.general.clients.edit.ui.default.name') : form.default_data.name)
                    }}
                  </v-toolbar-title>
                  <p class="white--text mb-2">{{
                      $vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.type.label')
                                              }} : {{
                      (_.isEmpty(form.default_data.type) ? $vuetify.lang.t('$vuetify.general.clients.edit.ui.default.type') : $vuetify.lang.t('$vuetify.general.clients._types.' + form.default_data.type))
                                              }}</p>
                </v-col>
              </v-row>

              <v-card-text class="px-5 px-sm-9 pb-0">
                <div class="py-6">
                  <v-row class="pt-0 d-flex flex-column">
                    <v-col class="p-0">
                      <p class="mb-0 font-weight-bold">
                        {{ $vuetify.lang.t('$vuetify.general.clients.edit.ui.data.accessible_websites.label') }}</p>
                    </v-col>
                    <v-col class="pt-0 pl-0">
                      <v-chip v-for="site in form.default_data.sites"
                              :key="site.id"
                              class="ma-2"
                      >
                        {{ site.value }}
                      </v-chip>
                      <span class="px-3"
                            v-if="_.size(form.default_data.sites)<=0">
                    -
                  </span>
                    </v-col>
                  </v-row>
                  <v-row class="pt-4 d-flex flex-column">
                    <v-col class="p-0">
                      <p class="mb-0 font-weight-bold">{{ $vuetify.lang.t('$vuetify.global.details') }} : </p>
                    </v-col>
                    <v-col class="pt-0">
                      <p class="font-weight-light mb-0"><span class="font-weight-bold text--disabled">{{
                          $vuetify.lang.t('$vuetify.global.created_at')
                                                                                                      }} :</span>
                        {{ moment(form.default_data.created_at).format('DD/MM/YYYY') }}</p>
                      <p class="font-weight-light mb-0"><span class="font-weight-bold text--disabled">{{
                          $vuetify.lang.t('$vuetify.global.updated_at')
                                                                                                      }} :</span>
                        {{ moment(form.default_data.updated_at).format('DD/MM/YYYY') }}</p>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="8" class="pt-0">
            <v-card outlined
                    :loading="ui.loading"
                    :disabled="ui.disabled"
                    class="rounded-lg overflow-hidden">
              <template slot="progress">
                <v-progress-linear
                  color="blue-grey darken-3"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row
                style="border-radius: 0"
                class="blue py-6 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="title white--text pl-0">
                    {{ $vuetify.lang.t('$vuetify.general.clients.edit.ui.title') }}
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-card-text class="px-0 pb-0">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select
                        v-model="form.data.type"
                        item-text="name"
                        item-value="value"
                        :items="types"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.type.label')+'*'"
                        :rules="form.rules.type"
                      >
                        <v-icon
                          slot="prepend"
                        >
                          mdi-checkbox-multiple-blank-circle
                        </v-icon>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-text-field
                        v-model="form.data.name"
                        :counter="75"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.name.label')+'*'"
                        :rules="form.rules.name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12 mt-6">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :counter="75"
                        v-model="form.data.address.name"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.address.label')+'*'"
                        :rules="form.rules.address.name"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <v-text-field
                        v-model="form.data.address.postal_code"
                        :counter="5"
                        :rules="form.rules.address.postal_code"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.postal_code.label')+'*'"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-text-field
                        v-model="form.data.address.city"
                        :rules="form.rules.address.city"
                        :counter="54"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.city.label')+'*'"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12 mt-2 mb-4">
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :counter="14"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.phone.label')"
                        v-model="form.data.phone"
                        :rules="form.rules.phone"
                        required
                      >
                        <v-icon
                          slot="prepend"
                        >
                          mdi-phone
                        </v-icon>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        :counter="64"
                        :label="$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.email.label')"
                        v-model="form.data.email"
                        :rules="form.rules.email"
                        required
                      >
                        <v-icon
                          slot="prepend"
                        >
                          mdi-email
                        </v-icon>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <p class="mt-6 pb-0 mb-0 text-caption">
                        {{ $vuetify.lang.t('$vuetify.global.form.required_fields') }}
                      </p>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions class="d-xs-block px-3 px-sm-7">
                <v-btn
                  text
                  v-on:click="reset()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  v-on:click="submit()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'GeneralClientsList'}">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </v-row>
</template>
<script>
import _ from 'lodash'

export default {
  data: () => ({
    types: [],
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    form: {
      valid: false,
      default_data: {},
      data: {
        type: '',
        name: '',
        address: {
          name: '',
          postal_code: '',
          city: ''
        },
        phone: '',
        email: ''
      },
      rules: {}
    }
  }),
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.$refs.form.reset()
      this.$refs.form.resetValidation()

      setTimeout(() => {
        this.form.data.type = this.form.default_data.type
        this.form.data.name = this.form.default_data.name
        this.form.data.address.name = this.form.default_data.address
        this.form.data.address.postal_code = this.form.default_data.postal_code
        this.form.data.address.city = this.form.default_data.city
        this.form.data.phone = this.form.default_data.phone
        this.form.data.email = this.form.default_data.email
      }, 10)
    },
    validate () {
      return this.$refs.form.validate()
    },
    async submit () {
      if (!this.validate()) {
        return false
      }

      try {
        this.ui.loading = true
        this.ui.disabled = true

        await this.axios.post('/general/accounts/edit/' + this.form.default_data.id, {
          type: this.form.data.type,
          name: this.form.data.name,
          address: this.form.data.address.name,
          city: this.form.data.address.city,
          postal_code: this.form.data.address.postal_code,
          phone: this.form.data.phone,
          email: this.form.data.email
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        await this.fetchAccount(this.form.default_data.id)

        this.reset()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.edited'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.fail'))
              break

            case 404:
              await this.$router.push({
                name: 'GeneralClientsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.not_find')
                  }
                }
              })
              break

            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    },
    async fetchAccount (id) {
      try {
        this.ui.loading = true
        this.ui.disabled = true

        this.form.default_data = (await this.axios.get('/general/accounts/one/' + id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.reset()
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            case 404:
              await this.$router.push({
                name: 'GeneralClientsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.not_find')
                  }
                }
              })
              break

            default:
              await this.$router.push({
                name: 'GeneralClientsList',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert-octagon',
                    type: 'error',
                    message: this.$vuetify.lang.t('$vuetify.general.clients.edit.notification.unknown_fetch')
                  }
                }
              })
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.ui.loading = false
        this.ui.disabled = false
      }
    }
  },
  watch: {
    'form.data.name': function (newVal, oldVal) {
      this.form.data.name = _.startCase(newVal)
    }
  },
  mounted () {
    this.fetchAccount(this.$router.currentRoute.params.id)
  },
  created () {
    // Define translations
    this.types = [
      {
        value: 'private_school',
        name: this.$vuetify.lang.t('$vuetify.general.clients._types.private_school')
      }, {
        value: 'public_school',
        name: this.$vuetify.lang.t('$vuetify.general.clients._types.public_school')
      }, {
        value: 'company',
        name: this.$vuetify.lang.t('$vuetify.general.clients._types.company')
      }, {
        value: 'local_government_authority',
        name: this.$vuetify.lang.t('$vuetify.general.clients._types.local_government_authority')
      }
    ]
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.name'),
        disabled: true,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.clients.name'),
        disabled: false,
        to: { name: 'GeneralClientsList' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.general.clients.edit.name'),
        disabled: true,
        to: { name: 'GeneralClientsEdition' }
      }
    ]
    this.form.rules = {
      type: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => _.includes(['private_school', 'public_school', 'company', 'local_government_authority'], value) || 'Le type selectionné n\'est pas autorisé.'
      ],
      name: [
        value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
        value => (value && value.length <= 75) || this.$vuetify.lang.t('$vuetify.global.max_char')
      ],
      address: {
        name: [
          value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
          value => (value && value.length <= 75) || this.$vuetify.lang.t('$vuetify.global.max_char')
        ],
        postal_code: [
          value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
          value => {
            const pattern = /[(0-9)]{5}$/
            return pattern.test(value) || this.$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.postal_code.rules.invalid')
          }
        ],
        city: [
          value => !!value || this.$vuetify.lang.t('$vuetify.global.required'),
          value => (value && value.length <= 54) || this.$vuetify.lang.t('$vuetify.global.max_char')
        ]
      },
      phone: [
        value => {
          const error = this.$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.phone.rules.invalid')
          if (_.size(value) <= 0) return true
          if (value.length > 14) return error
          const pattern = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/
          return ('' || pattern.test(value)) || error
        }
      ],
      email: [
        value => {
          if (_.size(value) <= 0) {
            return true
          }
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return (pattern.test(value)) || this.$vuetify.lang.t('$vuetify.general.clients.edit.ui.form.fields.email.rules.invalid')
        }
      ]
    }
  }
}
</script>
